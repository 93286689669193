var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientData && _vm.$can("read", "clients")
    ? _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "content-header" },
            [
              _c(
                "b-col",
                {
                  staticClass: "content-header-left mb-2",
                  attrs: { cols: "12", md: "9" }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "breadcrumbs-top" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "content-header-title float-left pr-1 mb-0"
                          },
                          [_vm._v(" Client Account ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "breadcrumb-wrapper" },
                          [
                            _c(
                              "b-breadcrumb",
                              [
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/cameras/list" } },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "align-text-top",
                                      attrs: { icon: "HomeIcon", size: "16" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/clients/list" } },
                                  [_vm._v(" Client Account ")]
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { active: "" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.clientData.display_name) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", show: _vm.clientData === undefined }
            },
            [
              _c("h4", { staticClass: "alert-heading" }, [
                _vm._v(" Error fetching client data ")
              ]),
              _c(
                "div",
                { staticClass: "alert-body" },
                [
                  _vm._v(" No client found with this clientname. Check "),
                  _c(
                    "b-link",
                    {
                      staticClass: "alert-link",
                      attrs: { to: { name: "apps-clients-list" } }
                    },
                    [_vm._v(" Client List ")]
                  ),
                  _vm._v(" . ")
                ],
                1
              )
            ]
          ),
          _vm.clientData
            ? [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "24", xl: "12", lg: "12", md: "12" } },
                      [
                        _c("client-view-client-info-card", {
                          attrs: { "client-data": _vm.clientData }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "24", xl: "12", lg: "12", md: "12" } },
                      [
                        _c("client-view-client-info-notes", {
                          attrs: { "client-data": _vm.clientData }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c("users-list", {
            attrs: {
              "client-name-prop": _vm.clientname,
              "client-display-name-prop": _vm.clientDisplayName
            }
          }),
          _c("client-sites-list", {
            attrs: {
              clientname: _vm.clientname,
              "client-display-name": _vm.clientDisplayName
            }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }