<template>
  <b-card v-if="$can('read', 'clients')">

    <b-row>

      <!-- Client Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        lg="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Client Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ clientData.display_name }}
              </h4>
            </div>
            <div
              v-if="$can('update', 'clients') && (loggedInUserData.group === 'Management' || loggedInUserData.group === 'EyeTrax_Admin')"
              class="d-flex flex-wrap"
            >
              <b-button
                :to="{ name: 'apps-clients-edit', params: { clientname: clientData.clientname } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="4"
        lg="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Client Name</span>
            </th>
            <td class="pb-50">
              {{ clientData.display_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Parent Client</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.parent_clientname }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 align-top">
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Services</span>
            </th>
            <td class="pb-50 text-capitalize">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <ul class="ml-0 pl-1">
                <li
                  v-for="item in servicesList"
                  :key="item.id"
                >
                  {{ item.value }}
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard, BButton, BRow, BCol,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      servicesList: [],
    }
  },
  setup() {
    const loggedInUserData = getUserData()

    return {
      loggedInUserData,
    }
  },
  created() {
    if (typeof this.clientData.services === 'undefined') {
      this.clientData.services = {}
    }
    if (typeof this.clientData.services.guard !== 'undefined' && this.clientData.services.guard === true) {
      this.servicesList.push({ value: 'Guard Services', id: 1 })
    }
    if (typeof this.clientData.services.eyetrax !== 'undefined' && this.clientData.services.eyetrax === true) {
      this.servicesList.push({ value: 'Eye Trax Services', id: 2 })
    }
    if (typeof this.clientData.services.guardtour !== 'undefined' && this.clientData.services.guardtour === true) {
      this.servicesList.push({ value: 'Guard Tour Services', id: 3 })
    }
    if (typeof this.clientData.services.tenant !== 'undefined' && this.clientData.services.tenant === true) {
      this.servicesList.push({ value: 'Tenant Services', id: 4 })
    }
    if (typeof this.clientData.services.privateinvestigation !== 'undefined' && this.clientData.services.privateinvestigation === true) {
      this.servicesList.push({ value: 'Private Investigation Services', id: 5 })
    }
    console.log('this.servicesList')
    console.log(this.servicesList)
  },
}
</script>

<style>

</style>
